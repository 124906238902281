import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useWindowWidth } from "hooks"
import { PropsWithChildren } from "react"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { ProgressBar } from "../../base/ProgressBar"
import s from "./UserInfoHeader.module.scss"

export const UserInfoHeader = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.header}>{children}</div>
}

type CompletedProps = {
  value: number
  maxValue: number
  label: string
  link: FiestaLinkProps
}
const Completed = ({ value, maxValue, label, link }: CompletedProps) => (
  <FiestaLink className={s.completed} {...link}>
    <img className={s.completed__icon} src={checkmarkCircleGreen} alt="" />
    <div className={s.completed__value}>
      {value}/{maxValue}
    </div>
    <div className={s.completed__label}>{label}</div>
  </FiestaLink>
)

type StarsProps = {
  value: number
  maxValue: number
}
const Stars = ({ value, maxValue }: StarsProps) => (
  <div className={s.stars}>
    <img className={s.stars__icon} src={starFilled} alt="" />
    <div className={s.stars__value}>
      {value}/{maxValue}
    </div>
  </div>
)

type MandatoryProps = {
  value: number
  maxValue: number
  label: string
  link: FiestaLinkProps
  isVisible: boolean
}
const Mandatory = ({
  label,
  value,
  maxValue,
  link,
  isVisible,
}: MandatoryProps) => {
  if (!isVisible) return null

  return (
    <FiestaLink className={s.mandatory} {...link}>
      <div className={s.mandatory__value}>
        {value}/{maxValue}
      </div>
      <div className={s.mandatory__label}>{label}</div>
    </FiestaLink>
  )
}

type ProgressProps = {
  value: number
  maxValue?: number
}
const Progress = ({ value, maxValue }: ProgressProps) => (
  <div className={s.progress}>
    <ProgressBar
      value={value}
      maxValue={maxValue}
      color="green-30"
      backgroundColor="green-10"
    />
  </div>
)

const Skeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__completed} />
    <div className={s.skeleton__stars} />
    <div className={s.skeleton__mandatory} />
  </div>
)

UserInfoHeader.Completed = Completed
UserInfoHeader.Stars = Stars
UserInfoHeader.Mandatory = Mandatory
UserInfoHeader.Progress = Progress
UserInfoHeader.Skeleton = Skeleton
