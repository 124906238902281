import { useQueryAll } from "bonzai"
import { custom, v1 } from "src/bonzai/bonzai"
import { getMaxStarsForProduct } from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { useVuplexStore } from "src/stores/useVuplexStore"
import { sendOpenProductMessage } from "src/vuplex/sendOpenProductMessage"
import { ProductPlaying } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const ProductPlayingLoader = () => {
  const productId = useVuplexStore((store) => store.productPlayingId)

  if (productId === undefined) return null

  return (
    <QueryBoundary>
      <Load productId={productId} />
    </QueryBoundary>
  )
}

type ProductProps = {
  productId: number
}
const Load = ({ productId }: ProductProps) => {
  const [product, status, playLinks] = useData(productId)
  const { product_type, no_score, image, titles } = product

  const pickText = usePickText()

  return (
    <ProductPlaying onClick={() => sendOpenProductMessage(product, playLinks)}>
      <ProductPlaying.Image src={image} />
      <ProductPlaying.Title title={pickText(titles)} />
      <ProductPlaying.Stars
        stars={status.stars}
        starsMax={getMaxStarsForProduct(product_type, !no_score)}
      />
      <ProductPlaying.Play progress={status.percentage} progressMax={100} />
    </ProductPlaying>
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v1.getProduct.useQuery([productId]),
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),
    custom.getPlayLinks.useQuery([productId]),
  ])
}
